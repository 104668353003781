import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';
import './Pagination.css';

const MAX_SHOW_PAGINATION = 6;
const MAX_PAGINATION_INDEX = 5;
const START_INDEX = 0;

const propTypes = {
    paginate: PropTypes.func.isRequired,
    selected: PropTypes.number,
    pages: PropTypes.arrayOf(
        PropTypes.shape({
            index: PropTypes.number,
            selected: PropTypes.bool
        })
    )
};

const defaultProps = {
    selected: null,
    pages: null
};
const Pagination = memo(function({ selected, pages, paginate }) {
                const pageNumbers = pages
        .slice(
            selected > MAX_SHOW_PAGINATION ? selected - MAX_SHOW_PAGINATION : START_INDEX,
            selected > MAX_SHOW_PAGINATION ? selected : MAX_PAGINATION_INDEX
        )
        .map(page => (
            <li key={page.index}>
            <button disabled={page.selected}
                aria-label={` ugrás a  ${page.index} oldalra`}
                className={`${page.selected ? 'edl-btn lg link page-num pagination-selected' : 'edl-btn lg link page-num'}`}
                onClick={() => paginate(page.index)}
            >
                {page.index}
            </button>
            </li>
        ));

    return (
        <Fragment>
            {pages.length > 0 ? (
                <ul className="pagination" aria-label="Lapozó">
                    <li key={0}>
                        <button disabled={selected === 1} aria-label="ugrás az első oldalra" className="edl-btn lg link fa fa-chevron-left" onClick={() => paginate(selected - 1)}></button>
                    </li>
                    {pageNumbers}
                    <li key={pages.length+1}>
                        <button disabled={selected === pages.length} aria-label="ugrás az utolsó oldalra" className="edl-btn lg link fa fa-chevron-right" onClick={() => paginate(selected + 1)}></button>
                    </li>
                </ul>
            ) : (
                <p>Nincsenek tartalmak</p>
            )}
        </Fragment>
    );
});
Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;
export default Pagination;
