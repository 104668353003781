import { INDEX_OFFSET, START_INDEX, ARTICLES_TO_SHOW } from '../../utils/constants';

export function firstRubricId(rubrics) {
    const [firstRubric] = rubrics;
    return firstRubric.id;
}

export function getArticles(rubrics) {
    let articles = [];
    rubrics.forEach(rubric => {
        rubric.articles.forEach(article => articles.push(article));
    });
    return articles;
}

export function getAuthors(rubrics) {
    const authors = [];
    getArticles(rubrics).forEach(article => authors.push(article.author));
    return authors;
}

export function getArticleById(rubrics) {
    const articleById = {};
    getArticles(rubrics).forEach(article => {
        articleById[article.id] = article;
    });
    return articleById;
}

export function getAuthorById(rubrics) {
    const authorById = {};
    getAuthors(rubrics).forEach(author => {
        authorById[author.id] = author;
    });
    return authorById;
}

export function getRubricById(rubrics) {
    const rubricById = {};
    rubrics.forEach(rubric => {
        rubricById[rubric.id] = rubric;
    });
    return rubricById;
}

export function getArticlesByAuthor(rubrics) {
    const articlesByAuthor = {};
    getAuthors(rubrics).forEach(
        author =>
            (articlesByAuthor[author.id] = getArticles(rubrics).filter(article => (article.author.id = author.id)))
    );
    return articlesByAuthor;
}

export function getRubricIdByName(rubrics) {
    const rubricIdByName = {};
    rubrics.forEach(rubric => {
        rubricIdByName[rubric.name] = rubric.id;
    });
    return rubricIdByName;
}

export function getFilteredArticleProps(rubrics) {
    const filteredArticleProps = {};
    getArticles(rubrics).forEach(article => {
        let rubric = article.rubric;
        if (typeof article.rubric === 'string') {
            rubric = { name: article.rubric, id: getRubricIdByName(rubrics)[article.rubric] };
        }
        const articleWithProps = { ...article, rubric };
        const {
            namespace,
            status,
            shared,
            content,
            attachedDocumentIds,
            created,
            promoted,
            pinned,
            name,
            ...usedProps
        } = articleWithProps;
        filteredArticleProps[article.id] = usedProps;
    });
    return filteredArticleProps;
}

export function getSelectedRubricArticles(rubrics, selectedRubricId) {
    return getRubricById(rubrics)[selectedRubricId].articles;
}

export function getTags(rubrics, selectedRubricId, selectedTags) {
    const tags = [];
    getSelectedRubricArticles(rubrics, selectedRubricId).forEach(article => {
        article.tags.forEach(tag => {
            if (!tags.includes(tag)) tags.push(tag);
        });
    });
    return tags.filter(t => !selectedTags.includes(t));
}

export function getAuthorTags(rubrics, selectedTags, selectedRubricId, selectedAuthorId) {
    const tags = [];
    getSelectedRubricArticles(rubrics, selectedRubricId)
        .filter(a => +a.author.id === +selectedAuthorId)
        .forEach(article => {
            article.tags.forEach(tag => {
                if (!tags.includes(tag)) tags.push(tag);
            });
        });
    return tags.filter(t => !selectedTags.includes(t));
}

export function getFilteredPaginatedArticles(rubrics, selectedRubricId, selectedTags, selectedArticlePage) {
    return getFilterSelectedRubricArticlesOnTags(rubrics, selectedRubricId, selectedTags).slice(
        (selectedArticlePage - INDEX_OFFSET) * ARTICLES_TO_SHOW,
        (selectedArticlePage - INDEX_OFFSET) * ARTICLES_TO_SHOW + ARTICLES_TO_SHOW
    );
}

export function getFilteredPaginatedArticlesByAuthor(
    rubrics,
    selectedRubricId,
    selectedTags,
    selectedAuthorId,
    selectedArticlePage
) {
    return getFilteredPaginatedArticles(rubrics, selectedRubricId, selectedTags, selectedArticlePage).filter(
        article => +article.author.id === +selectedAuthorId
    );
}

export function getSelectedRubric(rubrics, selectedRubricId) {
    const rubric = getRubricById(rubrics)[selectedRubricId];
    return { id: rubric.id, name: rubric.name };
}

export function getArticlePages(rubrics, selectedRubricId, selectedArticlePage, selectedTags) {
    const pageNumbers = [];
    const articlesArrayLength = getFilterSelectedRubricArticlesOnTags(rubrics, selectedRubricId, selectedTags).length;
    const pages =
        articlesArrayLength % ARTICLES_TO_SHOW === START_INDEX
            ? articlesArrayLength / ARTICLES_TO_SHOW
            : articlesArrayLength / ARTICLES_TO_SHOW + INDEX_OFFSET;
    for (let i = INDEX_OFFSET; i <= pages; i++) {
        pageNumbers.push({ index: i, selected: i === selectedArticlePage });
    }
    return pageNumbers;
}

export function getAuthorArticlePages(rubrics, selectedRubricId, selectedAuthorId, selectedTags, selectedArticlePage) {
    const pageNumbers = [];
    const articlesArrayLength = getFilterSelectedRubricArticlesOnTagsAndAuthor(
        rubrics,
        selectedRubricId,
        selectedAuthorId,
        selectedTags
    ).length;
    const pages =
        articlesArrayLength % ARTICLES_TO_SHOW === START_INDEX
            ? articlesArrayLength / ARTICLES_TO_SHOW
            : articlesArrayLength / 4 + INDEX_OFFSET;
    for (let i = INDEX_OFFSET; i <= pages; i++) {
        pageNumbers.push({ index: i, selected: i === selectedArticlePage });
    }
    return pageNumbers;
}

export function getFilterSelectedRubricArticlesOnTags(rubrics, selectedRubricId, selectedTags) {
    return getSelectedRubricArticles(rubrics, selectedRubricId).filter(article => {
        let includes = true;
        for (let i = START_INDEX; i < selectedTags.length; i++) {
            includes = article.tags.includes(selectedTags[i]);
            if (includes === false) break;
        }
        return includes;
    });
}

export function getFilterSelectedRubricArticlesOnTagsAndAuthor(
    rubrics,
    selectedRubricId,
    selectedAuthorId,
    selectedTags
) {
    return getSelectedRubricArticles(rubrics, selectedRubricId)
        .filter(article => +article.author.id === +selectedAuthorId)
        .filter(article => {
            let includes = true;
            for (let i = START_INDEX; i < selectedTags.length; i++) {
                includes = article.tags.includes(selectedTags[i]);
                if (includes === false) break;
            }
            return includes;
        });
}
