import React, { memo } from 'react';
import PropTypes from 'prop-types';
import Carousel from 'apertus-react-carousel';

import {
    getImageExtraClass,
    SINGLE,
    INDEX_MODIFIER,
    CAROUSEL,
    TEST_URL,
    correctRatio,
    THUMBNAIL_RATIO_CORRECTION
} from '../../utils/constants';

const propTypes = {
    images: PropTypes.arrayOf(PropTypes.object).isRequired,
    elementStyle: PropTypes.string.isRequired,
    caption: PropTypes.string,
    position: PropTypes.string
};

const defaultProps = {
    caption: null,
    positon: null
};

const ImageWrapper = props => <div style={styles.imageWrapper}>{props.children}</div>;

const ImageWidget = memo(function({ images, elementStyle, caption, position }) {
    if (elementStyle !== CAROUSEL) {
        const imageElements = images.map((image, index) => {
            const imageExtraclass = getImageExtraClass(index, position, images.length - INDEX_MODIFIER);
            return (
                <div
                    key={image.id}
                    className={`image-${image.position.toLowerCase()}-${image.size.toLowerCase()} ${imageExtraclass}`}
                >
                    <ImageWrapper>
                        <img
                            style={createTransformFromCropData(image.cropperData.option)}
                            src={process.env.NODE_ENV === 'production' ? image.url : TEST_URL + image.url}
                            alt={image.alt}
                        />
                    </ImageWrapper>
                    {elementStyle === SINGLE && <div className="caption">{caption}</div>}
                </div>
            );
        });
        return (
            <div className={`image-widget${elementStyle === SINGLE ? ' image-single' : ''}`}>
                <div className="paper" />
                {imageElements}
            </div>
        );
    } else {
        return (
            <div className="image-widget">
                <div className="paper" />
                <Carousel
                    slides={images.map(image => ({
                        id: image.id,
                        slide: (
                            <ImageWrapper>
                                <img
                                    style={createTransformFromCropData(image.cropperData.option)}
                                    key={image.id}
                                    src={process.env.NODE_ENV === 'production' ? image.url : TEST_URL + image.url}
                                    alt={image.alt}
                                />
                            </ImageWrapper>
                        ),
                        thumbnail: (
                            <div className="thumbnail-wrapper">
                                <img
                                    style={createTransformFromCropData(image.cropperData.option, true)}
                                    key={`${image.id}-thumb`}
                                    src={process.env.NODE_ENV === 'production' ? image.url : TEST_URL + image.url}
                                    alt={image.alt}
                                />
                            </div>
                        )
                    }))}
                />
            </div>
        );
    }
});
ImageWidget.propTypes = propTypes;
ImageWidget.defaultProps = defaultProps;
export default ImageWidget;

const createTransformFromCropData = (cropperData, isThumb) => ({
    transformOrigin: 'top left',
    transform: `scale(${
        isThumb ? cropperData.zoom * THUMBNAIL_RATIO_CORRECTION : cropperData.zoom
    }) translate(${correctRatio(cropperData.left)}px, ${correctRatio(cropperData.top).toFixed(5)}px)`
});

const styles = {
    imageWrapper: {
        height: '100%',
        width: '100%',
        overflow: 'hidden'
    }
};
