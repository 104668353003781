import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import './HighlightedArticle.css';
import {TEST_URL} from '../../utils/constants';
import Taglist from "../Taglist/Taglist";
import CategoryTitle from "../CategoryTitle/CategoryTitle";


const HighLightedArticle = (props) => {
    return (
        <section className="HighLightedArticle">
            {!props.top ? (
                <div className="section-title">
                    <CategoryTitle title={props.rubric.name} link={"/rovatok"}/>
                </div>

            ) : <h2 className="visibility__hidden">kiemelt cikk</h2>}
            <img className="HighLightedArticle__cover" alt=""
                 src={process.env.NODE_ENV === 'production' ? props.images.cover.url : TEST_URL + props.images.cover.url}/>
            <div className="HighLightedArticle__content">
                <div className="HighLightedArticle__content__header">
                    <span>{props.articleType}</span>
                    <Link className="edl-btn aqua link" to={`/${props.title.replace('?', '')}/${props.id}`}>{props.author.name}</Link>
                </div>
                <div className="HighLightedArticle__content__content">
                    <div className="HighLightedArticle__content__content__left">
                        <Link to={`/${props.title.replace('?', '')}/${props.id}`}
                              className="HighLightedArticle__content__content__left-title">
                            <h3>{props.title}</h3>
                        </Link>

                        <span className="HighLightedArticle__content__content__left-lead">{props.lead}</span>
                        <Taglist tags={props.tags} rubric={props.rubric}/>
                    </div>
                    <div className="HighLightedArticle__content__content__right">
                        <Link aria-label={props.title} to={`/${props.title.replace('?', '')}/${props.id}`}
                              className="edl-btn lime lg link round-background round">
                              <span className="fa fa-arrow-right fa-2x"/>
                        </Link>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default withRouter(HighLightedArticle);
