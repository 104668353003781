import React from 'react';
import {withRouter} from 'react-router-dom';
import './RubricSelectorWrapper.css';
import ArticleCard from '../ArticleCard/ArticleCard';
import RubricSelector from '../RubricSelector/RubricSelector';
import CategoryTitle from "../CategoryTitle/CategoryTitle";

const RubricSelectorWrapper = (props) => {

    return (
        <section className="RubricSelectorWrapper">
            <CategoryTitle title={props.selectedRubricArticles[0].rubric} link={"/rovatok"}></CategoryTitle>
            <div className="RubricSelectorWrapper__content">
                <div role={'list'} className="RubricSelectorWrapper__content__left">
                    {
                        props.selectedRubricArticles.slice(0, 3).map(article =>
                            <div key={article.id} className="RubricSelectorWrapper__content__left__item"
                                 role={"listitem"}>
                                <ArticleCard horizontal  {...props.filteredArticleProps[article.id]} />
                            </div>
                        )
                    }
                </div>
                <div className="RubricSelectorWrapper__content__right">
                    <RubricSelector rubrics={props.rubrics} selectedRubric={props.selectedRubric}
                                    changeRubric={props.changeRubric}/>
                </div>
            </div>
        </section>
    )
};
export default withRouter(RubricSelectorWrapper);
