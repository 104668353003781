import React from 'react';
import { withRouter } from 'react-router-dom';

export default function withScrollPositionHandler(WrappedComponent) {
    @withRouter
    class WithScrollPosition extends React.Component {
        componentWillUnmount() {
            sessionStorage.setItem(this.props.location.pathname, window.scrollY);
        }
        render() {
            return <WrappedComponent {...this.props} />;
        }
    }
    return WithScrollPosition;
}
