import React, { memo } from 'react';
import {Link, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';
import { TEST_URL } from '../../utils/constants';
import Taglist from "../Taglist/Taglist";

const propTypes = {
    id: PropTypes.number.isRequired,
    images: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    articleType: PropTypes.string.isRequired,
    history: PropTypes.object.isRequired,
    horizontal: PropTypes.bool,
    tags: PropTypes.arrayOf(PropTypes.string),
    lead: PropTypes.string
};

const defaultProps = {
    horizontal: false,
    lead: '',
    tags: []
};

const ArticleCard = memo(function({ horizontal, id, images, title, lead, tags, articleType, rubric, history }) {
    const image = horizontal ? images.portrait.url : images.landscape.url;
    const alt = horizontal ? images.portrait.alt : images.landscape.alt;
    return (
        <section className="card-holder ">
            <div className={horizontal ? 'article-card horizontal' : 'article-card'}>
                <Link className="article-card__image" to={`/${title.replace('?', '')}/${id}`}>
                    <img src={process.env.NODE_ENV === 'production' ? image : TEST_URL + image} alt={alt} />
                </Link>
                <div className="article-card__label">{articleType}</div>
                <Link tabIndex="0" className="article-card__title" to={`/${title.replace('?', '')}/${id}`}>
                    <h3>{title}</h3>
                </Link>
                <article className="article-card__text">{lead}</article>
                { horizontal ? '' :
                    <Taglist tags={tags} rubric={rubric}></Taglist>
                }

            </div>
        </section>
    );
});
ArticleCard.propTypes = propTypes;
ArticleCard.defaultProps = defaultProps;
export default withRouter(ArticleCard);
