import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {createStore, combineReducers, applyMiddleware, compose} from 'redux';
import thunk from 'redux-thunk';
import 'apertus-react-carousel/dist/ReactCarousel.scss';

import dataReducer from './store/reducers/data';
import announcementsReducer from './store/reducers/announcements';
import progkatReducer from './store/reducers/progkat';
import './styles/scss/main.scss';
import App from './App';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
    data: dataReducer,
    announcements: announcementsReducer,
    progkat: progkatReducer
});
const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const app = (
    <Provider store={store}>
        <App/>
    </Provider>
);
ReactDOM.render(app, document.getElementById('root'));

