import React, {memo, useState} from 'react';
import {withRouter} from 'react-router-dom';
import queryString from 'query-string';

const ADD = 'ADD';

const TagsFilter = memo(function ({tags, selectedTags, selectTag, deselectTag, history, location, selectedRubricId}) {
    const [currentIndex, setCurrentIndex] = useState(0);
    const rootHandleKeyUp = (e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            setCurrentIndex(0);
            document.getElementById('section-tag-select-list').children[0].focus();
        }
    }

    const itemHandleKeyUp = (e, tag,id) => {
        if (e.key === 'ArrowRight') {
            e.preventDefault();
            e.stopPropagation();
            tabStep(+1,id);
        } else if (e.key === 'ArrowLeft') {
            e.preventDefault();
            e.stopPropagation();
            tabStep(-1,id);
        } else if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            tagSelectionHandler(tag, ADD)
        }
    }

    const tabStep = (stepValue,id) => {
        let index = currentIndex + stepValue;
        if (index === document.getElementById(id).children.length) {
            index = 0;
        } else if (index === -1) {
            index = document.getElementById(id).children.length - 1;
        }
        document.getElementById(id).children[index].focus();
        setCurrentIndex(index);
    }

    function tagSelectionHandler(tag, type) {
        const tagsQueryStrings = queryString.parse(location.search)['címkék'];
        let selectedTags = [];
        if (tagsQueryStrings) {
            selectedTags = tagsQueryStrings.split(',');
        }
        if (type === ADD) {
            selectedTags.push(tag);
            selectTag(tag);
        } else {
            selectedTags.splice(selectedTags.indexOf(tag), 1);
            deselectTag(tag);
        }
        const tagString = selectedTags.length > 0 ? `&címkék=${selectedTags.join(',')}` : ``;
        history.push({
            search: `?rovat=${selectedRubricId}${tagString}`
        });
    }

    return (
        <div className="tags-filter">
            <section id="section-tag-select-list-selected" className="selected-tags">
                {selectedTags.map(tag => (

                    <button className="edl-btn tag" onClick={() => tagSelectionHandler(tag)}>
                        {tag}
                        <span className="fa fa-close"/>
                    </button>
                ))}
            </section>
            <section id="section-tag-select-list" tabIndex={0} onKeyDown={(e) => {
                rootHandleKeyUp(e)
            }}>
                {tags.map(tag => (
                    <button onKeyDown={(e) => {
                        itemHandleKeyUp(e, tag,'section-tag-select-list')
                    }} tabIndex={-1} className="edl-btn tag" onClick={() => tagSelectionHandler(tag, ADD)}>
                        {tag}
                    </button>
                ))}
            </section>
        </div>
    );
});
export default withRouter(TagsFilter);
