import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { connect } from 'react-redux';

import AuthorBox from '../../components/AuthorBox/AuthorBox';
import ArticleCard from '../../components/ArticleCard/ArticleCard';
import Pagination from '../../components/Pagination/Pagination';
import RubricSelector from '../../components/RubricSelector/RubricSelector';
import TagsFilter from '../../components/TagsFilter/TagsFilter';
import withQueryString from '../../hoc/withQueryString';
import withScrollPositionHandler from '../../hoc/withScrollPositionHandler';
import {
    SELECT_AUTHOR,
    CLEAR_FILTERS,
    PAGINATE,
    CHANGE_RUBRIC,
    SELECT_TAG,
    DESELECT_TAG
} from '../../store/actions/actionTypes';
import {
    getAuthorById,
    getFilteredPaginatedArticlesByAuthor,
    getFilteredArticleProps,
    getAuthorArticlePages,
    getAuthorTags,
    getSelectedRubric
} from '../../store/filters/data';

@withQueryString
@withScrollPositionHandler
class Author extends Component {
    componentDidMount() {
        this.props.selectAuthor(this.props.match.params.id);
        this.props.loadQueryStrings();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
            this.props.loadQueryStrings();
        }
    }

    componentWillUnmount() {
        this.props.clearFilters();
    }

    render() {
        const {
            paginate,
            changeRubric,
            selectTag,
            deselectTag,
            authorById,
            filteredPaginatedArticlesByAuthor,
            filteredArticleProps,
            selectedArticlePage,
            authorArticlePages,
            rubrics,
            selectedRubric,
            authorTags,
            selectedTags,
            selectedRubricId
        } = this.props;
        const author = authorById[this.props.match.params.id];
        if (!author) return <Redirect to={'/'} />;
        const renderArticles = filteredPaginatedArticlesByAuthor.map(article => {
            return <ArticleCard horizontal key={article.id} {...filteredArticleProps[article.id]} />;
        });
        return (
            <div className="author-page">
                <div className="main-content">
                    <div className="h4">cikkek és bejegyzések a szerzőtől</div>
                    <AuthorBox author={author} />
                    {renderArticles}
                    <Pagination selected={selectedArticlePage} pages={authorArticlePages} paginate={paginate} />
                </div>
                <aside>
                    <div className="h4">rovatválasztó</div>
                    <RubricSelector rubrics={rubrics} selectedRubric={selectedRubric} changeRubric={changeRubric} />
                    <div className="h4">szűrés címkék szerint</div>
                    <TagsFilter
                        tags={authorTags}
                        selectedTags={selectedTags}
                        selectTag={selectTag}
                        deselectTag={deselectTag}
                        selectedRubricId={selectedRubricId}
                    />
                </aside>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    rubrics: state.data.rubrics,
    selectedArticlePage: state.data.selectedArticlePage,
    selectedTags: state.data.selectedTags,
    selectedRubricId: state.data.selectedRubricId,
    selectedRubric: getSelectedRubric(state.data.rubrics, state.data.selectedRubricId),
    authorById: getAuthorById(state.data.rubrics),
    filteredArticleProps: getFilteredArticleProps(state.data.rubrics),
    authorTags: getAuthorTags(
        state.data.rubrics,
        state.data.selectedTags,
        state.data.selectedRubricId,
        state.data.selectedAuthorId
    ),
    authorArticlePages: getAuthorArticlePages(
        state.data.rubrics,
        state.data.selectedRubricId,
        state.data.selectedAuthorId,
        state.data.selectedTags,
        state.data.selectedArticlePage
    ),
    filteredPaginatedArticlesByAuthor: getFilteredPaginatedArticlesByAuthor(
        state.data.rubrics,
        state.data.selectedRubricId,
        state.data.selectedTags,
        state.data.selectedAuthorId,
        state.data.selectedArticlePage
    )
});

const mapDispatchToProps = dispatch => ({
    selectAuthor: id => dispatch({ type: SELECT_AUTHOR, payload: { id } }),
    clearFilters: () => dispatch({ type: CLEAR_FILTERS }),
    paginate: page => dispatch({ type: PAGINATE, payload: { page } }),
    changeRubric: id => dispatch({ type: CHANGE_RUBRIC, payload: { id } }),
    selectTag: tag => dispatch({ type: SELECT_TAG, payload: { tag } }),
    deselectTag: tag => dispatch({ type: DESELECT_TAG, payload: { tag } })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Author);
