import React from 'react';

import { getLrsSchema } from '../utils/constants';
import lrsAxios from '../axios/lrsAxios';

export default function withLrsRequestHandler(WrappedComponent) {
    class WithLrsRequestHandler extends React.Component {
        lrsRequestHandler = (verb, object, id) => {
            if (process.env.REACT_APP_LRS_ENABLED === 'true') {
                document.edlHeaderHelper.getUser(user => {
                    const email = user ? user.email : 'anonymous@apertus.hu';
                    const lrsData = getLrsSchema(email, verb, object, id);
                    lrsAxios.post('/', lrsData);
                });
            } else {
                console.log('LRS IS DISABLED');
            }
        };

        render() {
            return <WrappedComponent {...this.props} sendToLrs={this.lrsRequestHandler} />;
        }
    }
    return WithLrsRequestHandler;
}
