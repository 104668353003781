import axios from '../../axios/data-axios';

import { GET_STATIC_DATA_SUCCESS, GET_STATIC_DATA_FAILED } from './actionTypes';

export const loadStaticData = () => async dispatch => {
    try {
        const response = await axios.get();
        dispatch({ type: GET_STATIC_DATA_SUCCESS, payload: { data: response.data } });
    } catch (error) {
        dispatch({ type: GET_STATIC_DATA_FAILED, payload: { error } });
    }
};
