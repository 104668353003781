import React, { Component } from 'react';
import { connect } from 'react-redux';

import ArticleCard from '../../components/ArticleCard/ArticleCard';
import Pagination from '../../components/Pagination/Pagination';
import RubricSelector from '../../components/RubricSelector/RubricSelector';
import TagsFilter from '../../components/TagsFilter/TagsFilter';
import withQueryString from '../../hoc/withQueryString';
import withScrollPositionHandler from '../../hoc/withScrollPositionHandler';
import { CLEAR_FILTERS, PAGINATE, CHANGE_RUBRIC, SELECT_TAG, DESELECT_TAG } from '../../store/actions/actionTypes';
import {
    getFilteredPaginatedArticles,
    getFilteredArticleProps,
    getArticlePages,
    getTags,
    getSelectedRubric
} from '../../store/filters/data';

@withScrollPositionHandler
@withQueryString
class Rubrics extends Component {
    componentDidMount() {
        this.props.loadQueryStrings();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.search !== this.props.location.search) {
            this.props.loadQueryStrings();
        }
    }

    componentWillUnmount() {
        this.props.clearFilters();
    }
    render() {
        const {
            paginate,
            changeRubric,
            selectTag,
            deselectTag,
            filteredPaginatedArticles,
            filteredArticleProps,
            articlePages,
            selectedArticlePage,
            rubrics,
            selectedRubric,
            tags,
            selectedTags,
            selectedRubricId
        } = this.props;
        const renderArticles = filteredPaginatedArticles.map(article => {
            return <ArticleCard horizontal key={article.id} {...filteredArticleProps[article.id]} />;
        });
        return (
            <div className="rubrics-page">
                <div className="main-content">
                    <div className="h4">cikkek és bejegyzések a rovatban</div>
                    {renderArticles}
                    <Pagination selected={selectedArticlePage} pages={articlePages} paginate={paginate} />
                </div>
                <aside>
                    <div className="h4">rovatválasztó</div>
                    <RubricSelector rubrics={rubrics} selectedRubric={selectedRubric} changeRubric={changeRubric} />
                    <div className="h4">szűrés címkék szerint</div>
                    <TagsFilter
                        tags={tags}
                        selectedTags={selectedTags}
                        selectTag={selectTag}
                        deselectTag={deselectTag}
                        selectedRubricId={selectedRubricId}
                    />
                </aside>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    selectedArticlePage: state.data.selectedArticlePage,
    rubrics: state.data.rubrics,
    selectedTags: state.data.selectedTags,
    selectedRubricId: state.data.selectedRubricId,
    selectedRubric: getSelectedRubric(state.data.rubrics, state.data.selectedRubricId),
    tags: getTags(state.data.rubrics, state.data.selectedRubricId, state.data.selectedTags),
    filteredPaginatedArticles: getFilteredPaginatedArticles(
        state.data.rubrics,
        state.data.selectedRubricId,
        state.data.selectedTags,
        state.data.selectedArticlePage
    ),
    filteredArticleProps: getFilteredArticleProps(state.data.rubrics),
    articlePages: getArticlePages(
        state.data.rubrics,
        state.data.selectedRubricId,
        state.data.selectedArticlePage,
        state.data.selectedTags
    )
});

const mapDispatchToProps = dispatch => ({
    clearFilters: () => dispatch({ type: CLEAR_FILTERS }),
    paginate: page => dispatch({ type: PAGINATE, payload: { page } }),
    changeRubric: id => dispatch({ type: CHANGE_RUBRIC, payload: { id } }),
    selectTag: tag => dispatch({ type: SELECT_TAG, payload: { tag } }),
    deselectTag: tag => dispatch({ type: DESELECT_TAG, payload: { tag } })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Rubrics);
