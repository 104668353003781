import {
    GET_STATIC_DATA_SUCCESS,
    GET_STATIC_DATA_FAILED,
    PAGINATE,
    CHANGE_RUBRIC,
    CLEAR_FILTERS,
    SELECT_TAG,
    DESELECT_TAG,
    SELECT_AUTHOR
} from '../actions/actionTypes';
import { updateState } from '../utilityFunctions';

const initialState = {
    portal: null,
    domain: null,
    rubrics: [],
    pinnedArticles: [],
    primaryHighlightedRubric: null,
    secondaryHighlightedRubric: null,
    selectedRubricId: null,
    selectedAuthorId: null,
    selectedArticlePage: 1,
    selectedTags: [],
    staticDataLoaded: false
};

const getStaticDataSuccess = (state, { data }) => {
    const [firstRubric] = data.rubrics;
    return updateState(state, {
        staticDataLoaded: true,
        portal: data.portal,
        domain: data.domain,
        rubrics: data.rubrics,
        pinnedArticles: data.pinnedArticles,
        primaryHighlightedRubric: data.primaryHighlightedRubric,
        secondaryHighlightedRubric: data.secondaryHighlightedRubric,
        selectedRubricId: firstRubric ? firstRubric.id : null
    });
};

const clearFilters = state => {
    const [firstRubric] = state.rubrics;
    return updateState(state, {
        selectedRubricId: firstRubric ? firstRubric.id : null
    });
};

const selectTag = (state, { tag }) => {
    if (!state.selectedTags.includes(tag)) {
        const selectedTags = [...state.selectedTags, tag];
        return updateState(state, { selectedTags, selectedArticlePage: 1 });
    }
};

const deselectTag = (state, { tag }) => {
    const selectedTags = state.selectedTags.filter(t => t !== tag);
    return updateState(state, { selectedTags, selectedArticlePage: 1 });
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_STATIC_DATA_SUCCESS:
            return getStaticDataSuccess(state, payload);
        case GET_STATIC_DATA_FAILED:
            return updateState(state, { staticDataLoaded: false });
        case PAGINATE:
            return updateState(state, { selectedArticlePage: payload.page });
        case CHANGE_RUBRIC:
            return updateState(state, { selectedRubricId: payload.id, selectedTags: [] });
        case CLEAR_FILTERS:
            return clearFilters(state);
        case SELECT_TAG:
            return selectTag(state, payload);
        case DESELECT_TAG:
            return deselectTag(state, payload);
        case SELECT_AUTHOR:
            return updateState(state, { selectedAuthorId: payload.id });
        default:
            return state;
    }
};
