import axios from 'axios';

import { getLrsAuthHeader } from '../utils/constants';

export default axios.create({
    baseURL: `${process.env.REACT_APP_LRS_HOST}/${process.env.REACT_APP_LRS_URI}`,
    headers: {
        Authorization: getLrsAuthHeader(),
        'X-Experience-API-version': process.env.REACT_APP_LRS_XAPI_VERSION,
        'Content-Type': 'application/json; charset=utf-8'
    }
});
