import { Component } from 'react';
import { withRouter } from 'react-router-dom';

@withRouter
class ScrollToTop extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            if (this.props.history.action === 'PUSH' || this.props.history.action === 'REPLACE') {
                window.scroll(0, 0);
            } else {
                window.scroll(0, sessionStorage.getItem(this.props.location.pathname) || 0);
            }
        }
    }

    render() {
        return this.props.children;
    }
}
export default ScrollToTop;
