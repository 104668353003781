import React from 'react';
import {Link, withRouter} from 'react-router-dom';
import './CategoryTitle.css';

const CategoryTitle = (props) => {
    return (
        <div className="CategoryTitle">
            <Link className="edl-btn lime outline lg" to={props.link}>
                <span className="fa fa-newspaper"/>
                <h2>{props.title}</h2>
            </Link>
        </div>
    )
};
export default withRouter(CategoryTitle);
