import React, { memo } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    text: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};

const Announcement = memo(function({ text, title }) {
    return (
        <div className="content">
            <div className="content__title" dangerouslySetInnerHTML={{ __html: title }} />
            <p className="u-margin-top-small" dangerouslySetInnerHTML={{ __html: text.replaceAll('_blank', '_self') }} />
        </div>
    );
});
Announcement.propTypes = propTypes;
export default Announcement;
