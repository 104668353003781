import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';

import RubricSelectorWrapper from '../../components/RubricSelectorWrapper/RubricSelectorWrapper';
import Highlighted from '../../components/Highlighted/Highlighted';
import withQueryString from '../../hoc/withQueryString';
import { REGISTRATION, NEXT } from '../../utils/constants';
import withScrollPositionHandler from '../../hoc/withScrollPositionHandler';
import { loadAnnouncements } from '../../store/actions';
import { PAGINATE_ANNOUNCEMENT, CHANGE_RUBRIC } from '../../store/actions/actionTypes';
import {
    getArticleById,
    getSelectedRubric,
    getSelectedRubricArticles,
    getFilteredArticleProps
} from '../../store/filters/data';

@withScrollPositionHandler
@withQueryString
class Home extends Component {
    state = {
        intervalId: null,
        intervalStarted: false
    };

    componentDidMount() {
        this.props.loadQueryStrings();
        this.props.loadAnnouncements();
    }

    componentDidUpdate(prevProps) {
        const { announcements, currentContent } = this.props;
        if (!this.state.intervalStarted && (announcements.length >= 1 && currentContent !== announcements[0])) {
            this.setInterval();
            this.setState({ intervalStarted: true });
        }
        if (prevProps.location.search !== this.props.location.search) {
            this.props.loadQueryStrings();
        }
    }

    componentWillUnmount() {
        clearInterval(this.state.intervalId);
    }

    restartInterval = () => {
        clearInterval(this.state.intervalId);
        this.setInterval();
    };

    setInterval = () => this.setState({ intervalId: setInterval(() => this.props.paginate(NEXT), 10000) });

    render() {
        const {
            paginate,
            currentContent,
            prevContent,
            animationDirection,
            announcements,
            articleById,
            pinnedArticles,
            filteredArticleProps,
            rubrics,
            selectedRubric,
            selectedRubricArticles,
            primaryHighlightedRubric,
            secondaryHighlightedRubric,
            changeRubric
        } = this.props;
        return (
            <Fragment>
                <Highlighted
                    key="firstHighlighted"
                    pinned
                    restartInterval={this.restartInterval}
                    paginate={paginate}
                    currentContent={currentContent}
                    prevContent={prevContent}
                    animationDirection={animationDirection}
                    pinnedArticles={pinnedArticles}
                    articleById={articleById}
                    filteredArticleProps={filteredArticleProps}
                    showArrows={
                        announcements.length > 1 || (announcements.length === 1 && currentContent !== announcements[0])
                    }
                    showAnnouncements={announcements.length > 0 || currentContent === REGISTRATION}
                />
                <Highlighted
                    key="secondHighlighted"
                    rubric={primaryHighlightedRubric}
                    articleById={articleById}
                    filteredArticleProps={filteredArticleProps}
                    changeRubric={changeRubric}
                />
                <Highlighted
                    key="thirdHighlighted"
                    rubric={secondaryHighlightedRubric}
                    articleById={articleById}
                    filteredArticleProps={filteredArticleProps}
                    changeRubric={changeRubric}
                />
                {
                <RubricSelectorWrapper
                    changeRubric={changeRubric}
                    selectedRubric={selectedRubric}
                    selectedRubricArticles={selectedRubricArticles}
                    filteredArticleProps={filteredArticleProps}
                    rubrics={rubrics}
                />
                }
            </Fragment>
        );
    }
}

const mapStateToProps = state => ({
    announcements: state.announcements.announcements,
    animationDirection: state.announcements.animationDirection,
    prevContent: state.announcements.prevContent,
    currentContent: state.announcements.currentContent,
    rubrics: state.data.rubrics,
    pinnedArticles: state.data.pinnedArticles,
    primaryHighlightedRubric: state.data.primaryHighlightedRubric,
    secondaryHighlightedRubric: state.data.secondaryHighlightedRubric,
    selectedRubric: getSelectedRubric(state.data.rubrics, state.data.selectedRubricId),
    articleById: getArticleById(state.data.rubrics),
    filteredArticleProps: getFilteredArticleProps(state.data.rubrics),
    selectedRubricArticles: getSelectedRubricArticles(state.data.rubrics, state.data.selectedRubricId)
});

const mapDispatchToProps = dispatch => ({
    loadAnnouncements: () => dispatch(loadAnnouncements()),
    paginate: direction => dispatch({ type: PAGINATE_ANNOUNCEMENT, payload: { direction } }),
    changeRubric: id => dispatch({ type: CHANGE_RUBRIC, payload: { id } })
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Home);
