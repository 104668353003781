import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { CHANGE_RUBRIC, SELECT_TAG, CLEAR_FILTERS } from '../store/actions/actionTypes';

export default function withQueryString(WrappedComponent) {
    class WithQueryString extends React.Component {
        loadQueryStrings = () => {
            const { location, changeRubric, selectTag, clearFilters } = this.props;
            if (location.search) {
                if (queryString.parse(location.search).rovat) {
                    const selectedRubricID = +queryString.parse(location.search).rovat;
                    changeRubric(selectedRubricID);
                }
                if (queryString.parse(location.search)['címkék']) {
                    const selectedTags = queryString.parse(location.search)['címkék'].split(',');
                    selectedTags.forEach(tag => selectTag(tag));
                }
            } else {
                clearFilters();
            }
        };

        render() {
            return <WrappedComponent {...this.props} loadQueryStrings={this.loadQueryStrings} />;
        }
    }
    const mapDispatchToProps = dispatch => ({
        changeRubric: id => dispatch({ type: CHANGE_RUBRIC, payload: { id } }),
        selectTag: tag => dispatch({ type: SELECT_TAG, payload: { tag } }),
        clearFilters: () => dispatch({ type: CLEAR_FILTERS })
    });

    return connect(
        null,
        mapDispatchToProps
    )(WithQueryString);
}
