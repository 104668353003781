import { GET_ANNOUNCEMENTS_SUCCESS, PAGINATE_ANNOUNCEMENT } from '../actions/actionTypes';
import { NEXT, PREV, REGISTRATION } from '../../utils/constants';
import { updateState } from '../utilityFunctions';

const initialState = {
    announcements: [],
    currentContent: null,
    prevContent: null,
    animationDirection: NEXT
};

const getAnnouncements = (state, { messages, user }) => {
    const announcements = messages.map(message => responseMapper(message));
    let currentContent = state.currentContent;
    if (user) {
        currentContent = [...announcements].shift();
    } else {
        announcements.push(REGISTRATION);
        currentContent = announcements.shift();
    }
    return updateState(state, { currentContent, announcements });
};

const responseMapper = message => {
    const match = /^(.+?)!/.exec(message.szoveg);
    let title;
    let text;
    if (match) {
        title = match[0];
        text = message.szoveg.slice(title.length);
    } else {
        title = '';
        text = message.szoveg;
    }
    title = title.replace(/<\/?[^>]+(>|$)/g, '');
    return {
        title,
        text,
        id: message.id
    };
};
const paginate = (state, { direction }) => {
    switch (direction) {
        case NEXT:
            let announcements = [...state.announcements];
            announcements.push(state.currentContent);
            let prevContent = state.currentContent;
            let currentContent = announcements.shift();
            return updateState(state, { animationDirection: NEXT, announcements, prevContent, currentContent });
        case PREV:
            announcements = [...state.announcements];
            announcements.unshift(state.currentContent);
            prevContent = state.currentContent;
            currentContent = announcements.pop();
            return updateState(state, { animationDirection: PREV, announcements, prevContent, currentContent });
        default:
            return updateState(state);
    }
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_ANNOUNCEMENTS_SUCCESS:
            return getAnnouncements(state, payload);
        case PAGINATE_ANNOUNCEMENT:
            return paginate(state, payload);
        default:
            return state;
    }
};
