import React from 'react';
import './RegistrationCard.css';
function RegistrationCard() {
    return (
        <div className="content registration-card">
            <div className="content__title">Üdvözöljük a Probono továbbképzési rendszerében!</div>
            <a tabIndex="0" href={`${window.location.origin}/userreg`} className="edl-btn lime lg RegistrationCard__button">
                regisztrálok
            </a>
            <p className="content__login-text">
                Már van felhasználói fiókja?&nbsp;
                <a tabIndex="0" href={`${window.location.origin}/sso/login?service=${encodeURIComponent(window.location)}`}>
                    Bejelentkezés
                </a>
            </p>
        </div>
    );
}
export default RegistrationCard;
