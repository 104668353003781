import React, {Component} from 'react';
import {Route, Switch, Redirect, HashRouter as Router} from 'react-router-dom';
import {connect} from 'react-redux';
import ReactGA from 'react-ga';

import Home from './containers/Home/Home';
import Article from './containers/Article/Article';
import Author from './containers/Author/Author';
import Rubrics from './containers/Rubrics/Rubrics';
import NotFound from './components/NotFound/NotFound';
import {loadStaticData} from './store/actions';
import ScrollTop from './hoc/ScrollTop';

class App extends Component {
    constructor(props) {
        super(props);
        window.document.addEventListener('EDL_LAYOUT_CHOOSED', this.layoutChoosedListener);
    }

    async componentDidMount() {
        initializeReactGA();
        await this.props.loadStaticData();
        setTimeout(() => {
            document['edlHeaderHelper'].selectMenu('MAGAZIN');
            document['edlHeaderHelper'].setTitle('Probono Magazin');
            document['edlHeaderHelper'].renderFooter();
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        setTimeout(() => {
            document['edlHeaderHelper'].renderSkipLinks();
        });
    }

    componentWillUnmount() {
        window.document.removeEventListener('EDL_LAYOUT_CHOOSED', this.layoutChoosedListener);
    }

    layoutChoosedListener = () => (window.location.href = `${window.location.origin}/magazin`);

    render() {
        return (
            <main>
                {this.props.staticDataLoaded ? (
                    <div id="main" className="main">
                        <Router>
                            <ScrollTop>
                                <Switch>
                                    <Route path="/" exact component={props => <Home {...props} />}/>
                                    <Route path="/:title/:id" exact component={props => <Article {...props} />}/>
                                    <Route
                                        path="/szerzo/:author/:id"
                                        exact
                                        component={props => <Author {...props} />}
                                    />
                                    <Route path="/rovatok" exact component={props => <Rubrics {...props} />}/>
                                    <Route path="/404" exact component={props => <NotFound {...props} />}/>
                                    <Redirect to="/404"/>
                                </Switch>
                            </ScrollTop>
                        </Router>
                        <div id="apertus-edl-footer"/>
                    </div>
                ) : null}
            </main>
        );
    }
}

const mapStateToProps = state => ({
    staticDataLoaded: state.data.staticDataLoaded
});

const mapDispatchToProps = dispatch => ({
    loadStaticData: () => dispatch(loadStaticData())
});
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);

function initializeReactGA() {
    ReactGA.initialize('UA-46822734-8');
    ReactGA.pageview('/');
}
