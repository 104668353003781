import { Base64 } from 'js-base64';

export const NEXT = 'NEXT';
export const PREV = 'PREV';

export const REGISTRATION = 'REGISTRATION';
export const IMAGE = 'IMAGE';
export const LANDSCAPE = 'LANDSCAPE';

export const TEST_URL = 'https://probono.uni-nke.hu/magazin/';

export const RATING_STAR_FULL_CLASS_NAME = 'star--full fa fa-star';
export const RATING_STAR_HALF_CLASS_NAME = 'star--half fa fa-star-half-o';
export const RATING_STAR_EMPTY_CLASS_NAME = 'star--empty fa fa-star';
export const RATING_HALF_STAR_MIN = 0.3;
export const RATING_HALF_STAR_MAX = 0.7;
export const RATING_STARS_ARRAY = [1, 2, 3, 4, 5];
export const CLIPBOARD_TIMEOUT = 5000;
export const RATIO_CORRECTION = 0.625;
export const THUMBNAIL_RATIO_CORRECTION = 0.2;

export const START_INDEX = 0;
export const INDEX_OFFSET = 1;
export const ARTICLES_TO_SHOW = 4;

export const SINGLE = 'SINGLE';
export const CAROUSEL = 'CAROUSEL';
export const INDEX_MODIFIER = 1;
export const ARTICLE_TEXT = 'ARTICLE_TEXT';
export const ARTICLE_IMAGE = 'ARTICLE_IMAGE';

export const carouselAnimation = {
    [PREV]: {
        slideInAnimation: 'animation-wrapper--animation-slide-in-left',
        slideOutAnimation: 'animation-wrapper--animation-slide-out-right'
    },
    [NEXT]: {
        slideInAnimation: 'animation-wrapper--animation-slide-in-right',
        slideOutAnimation: 'animation-wrapper--animation-slide-out-left'
    }
};

export const correctRatio = num => (num / RATIO_CORRECTION / RATIO_CORRECTION) * 1;

export function getImageExtraClass(index, position, lastItemIndex) {
    if (position) {
        return position === 'center'
            ? null
            : index === 0
            ? `border-rad-${position}-left`
            : index === lastItemIndex
            ? `border-rad-${position}-right`
            : null;
    } else {
        return index === 0
            ? 'border-rad-top-left border-rad-bottom-left'
            : index === lastItemIndex
            ? 'border-rad-top-right border-rad-bottom-right'
            : null;
    }
}

export function getPosition(elements, index, elementStyle) {
    if (index >= INDEX_MODIFIER && elementStyle !== SINGLE) {
        if (
            elements[index - INDEX_MODIFIER] &&
            elements[index - INDEX_MODIFIER].elementType === ARTICLE_IMAGE &&
            elements[index + INDEX_MODIFIER] &&
            elements[index + INDEX_MODIFIER].elementType === ARTICLE_IMAGE
        ) {
            return 'center';
        }
        if (elements[index - INDEX_MODIFIER] && elements[index - INDEX_MODIFIER].elementType === ARTICLE_IMAGE) {
            return 'bottom';
        }
        if (elements[index + INDEX_MODIFIER] && elements[index + INDEX_MODIFIER].elementType === ARTICLE_IMAGE)
            return 'top';
    }
    return null;
}

export const getLrsSchema = (email = 'anonymous@apertus.hu', verb, object, id) => [
    {
        actor: { mbox: `mailto:${email}` },
        verb: {
            id: `http://adlnet.gov/expapi/verbs/${verb}`,
            display: {
                'en-GB': verb
            }
        },
        object: {
            objectType: 'Activity',
            id: `http://www.apertus.hu/magazin/object/${object}/${id}`,
            definition: {
                type: `http://adlnet.gov/expapi/activities/${object}`
            }
        }
    }
];

export const getLrsAuthHeader = () => {
    const auth = `${process.env.REACT_APP_LRS_USERNAME}:${process.env.REACT_APP_LRS_PASSWORD}`;
    const encodedAuth = Base64.encode(auth);
    return `Basic ${encodedAuth}`;
};
