import React, {memo} from 'react';
import {Link, withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import {TEST_URL} from '../../utils/constants';

const propTypes = {
    author: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired
};

const AuthorBox = memo(function ({author, history}) {
    return (
        <div className="author-box">
            <div className="inner">
                <div className="inner__image">
                    <Link to={`/szerzo/${author.name}/${author.id}`} >
                    <img
                        src={process.env.NODE_ENV === 'production' ? author.image.url : TEST_URL + author.image.url}
                        alt=""
                        className="profile"
                    />
                    </Link>
                </div>

                <div className="text">
                    <Link className="name" to={`/szerzo/${author.name}/${author.id}`}>{author.name}</Link>
                    <p>
                        {author.post} <br/> {author.department}
                    </p>
                    <p>{author.description}</p>
                </div>
            </div>
        </div>
    );
});
AuthorBox.propTypes = propTypes;
export default withRouter(AuthorBox);
