import { GET_PROGKAT_CARD_INFO_SUCCESS, CLEAR_PROGKAT_CARD_INFO } from '../actions/actionTypes';
import { IMAGE, LANDSCAPE } from '../../utils/constants';
import { updateState } from '../utilityFunctions';

const initialState = {
    articleProgKatCardInfo: null
};

const getProgkatCardInfo = (state, { media, name, meta, id }) => {
    const articleProgKatCardInfo = {
        id,
        name,
        institution: meta.institution,
        image: (
            media.find(img => img.type === IMAGE && img.meta.aspectRatio === LANDSCAPE) || {
                url: 'https://probono-test.uni-nke.hu/magazin/image/probono-magazin-teszt/HERO_polg.png'
            }
        ).url,
        group: meta.PRG_GROUP,
        type: meta.PRG_TYPE,
        rating: {
            avg: meta.rating ? meta.rating.average_rating : 0,
            count: meta.rating ? meta.rating.count : 0
        }
    };
    return updateState(state, { articleProgKatCardInfo });
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case GET_PROGKAT_CARD_INFO_SUCCESS:
            return getProgkatCardInfo(state, payload.info);
        case CLEAR_PROGKAT_CARD_INFO:
            return updateState(state, { articleProgKatCardInfo: null });
        default:
            return state;
    }
};
