import React, {useState} from 'react';
import {Link, withRouter} from 'react-router-dom';
import './Taglist.css';


const Taglist = (props) => {
    const [currentIndex, setCurrentIndex] = useState(undefined);
    const Id = 'section' + Math.floor(Math.random() * 20000) + 1;
    const rootHandleKeyUp = (e) => {
        if (e.key === 'Enter') {
            if (currentIndex === undefined) {
                setCurrentIndex(0);
                tabStep(0);
            }
        } else if (e.key === 'Tab') {
            setCurrentIndex(undefined);
        }
    };
    const itemHandleKeyUp = (e) => {
        for(let i = 0; i < document.getElementById(Id).children.length; i++) {
            document.getElementById(Id).children[i].tabIndex = -1;
        }
        if (e.key === 'ArrowRight' || e.key === 'ArrowDown') {
            e.preventDefault();
            e.stopPropagation();
            tabStep(+1);
        } else if (e.key === 'ArrowLeft' || e.key === 'ArrowUp') {
            e.preventDefault();
            e.stopPropagation();
            tabStep(-1);
        }
    };

    const tabStep = (stepValue) => {
        let index = (currentIndex || 0) + stepValue;
        if (index === document.getElementById(Id).children.length) {
            index = 0;
        } else if (index === -1) {
            index = document.getElementById(Id).children.length - 1;
        }
        document.getElementById(Id).children[index].tabIndex = 0;
        document.getElementById(Id).children[index].focus();
        setCurrentIndex(index);
    };

    return  (
        <section id={Id} role={"list"} className="taglist" tabIndex={props.tags.length ? 0 : -1} onKeyDown={rootHandleKeyUp} aria-label="Kategóriák">
            {props.tags.map((tag, index) => (
                <Link
                    tabIndex="-1"
                    role="listitem"
                    key={index}
                    onKeyDown={itemHandleKeyUp}
                    className="edl-btn gray sm light"
                    to={`/rovatok?rovat=${props.rubricId || props.rubric.id}&címkék=${tag}`}>
                    {tag}
                </Link>
            ))}
        </section>
    );
};
export default withRouter(Taglist);

