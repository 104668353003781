import React from 'react';

export default function NotFound({ history }) {
    return (
        <div key="not-found" className="not-found">
            <img className="image" src="assets/not-found.svg" alt="" />
            <div className="main-box">
                <div className="text">A keresett oldal nem található</div>
                <div className="button button--blue button--borrad-21" onClick={() => history.push('/')}>
                    TOVÁBB A FŐOLDALRA
                </div>
                <div style={{marginTop: '2rem'}} className="button button--blue button--borrad-21" onClick={() => history.goBack()}>
                    VISSZA AZ ELŐZŐ OLDALRA
                </div>
            </div>
        </div>
    );
}
