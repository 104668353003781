import React, {memo} from 'react';
import {Link, withRouter} from 'react-router-dom';
import './RubricSelector.css';
const RubricSelector = memo(function ({rubrics, selectedRubric}) {

    return (
        <div role={'list'} className="section-selector">
            {rubrics.map(rubric =>
                (
                    <Link role={"listitem"} key={rubric.id}
                          className={(rubric.id === selectedRubric.id) ? 'section-item selected' : 'section-item'}
                          to={`/rovatok?rovat=${rubric.id}`}>
                        {rubric.name}
                    </Link>
                )
            )}
        </div>
    );
});
export default withRouter(RubricSelector);
