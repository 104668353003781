import React, { memo, useState } from 'react';

import {
    RATING_STAR_FULL_CLASS_NAME,
    RATING_STAR_EMPTY_CLASS_NAME,
    RATING_STAR_HALF_CLASS_NAME,
    RATING_STARS_ARRAY,
    RATING_HALF_STAR_MIN,
    RATING_HALF_STAR_MAX
} from '../../utils/constants';

const getClass = (pos, avg) => {
    const avgSub = Math.abs((pos - avg).toFixed(1));
    if (pos <= avg || avg > 4 + RATING_HALF_STAR_MAX) return RATING_STAR_FULL_CLASS_NAME;
    if (avgSub >= RATING_HALF_STAR_MIN && avgSub <= RATING_HALF_STAR_MAX) {
        return RATING_STAR_HALF_CLASS_NAME;
    }
    return RATING_STAR_EMPTY_CLASS_NAME;
};

const ProgKatCard = memo(function({ id, name, institution, image, group, type, rating, sendToLrs }) {
    const [hover, setHover] = useState(false);
    const hoverHandlerProps = { onMouseEnter: () => setHover(true), onMouseLeave: () => setHover(false) };

    return (
        <div id="progkat" key={id} className="progkat">
            <a href={`${window.location.origin}/katalogus`} className="button button--green">
                <span className="fa fa-graduation-cap" />
                <div>Programkatalógus</div>
            </a>
            <div className="review">
                <div className="label">
                    {group} - {type} program
                </div>
                <div className="from">{institution}</div>
                <a className={`progkat__title${hover ? ' hover' : ''}`} href={`/katalogus/katalogus-elem/${id}`} {...hoverHandlerProps}>{name}</a>
                <a
                    aria-label={name}
                    href={`${window.location.origin}/katalogus/katalogus-elem/${id}`}
                    className={`arrow${hover ? ' hover' : ''}`}
                    {...hoverHandlerProps}
                >
                    <span className="fa fa-arrow-right" />
                </a>
                <div className="review__information">
                    <div className="star">
                        {RATING_STARS_ARRAY.map(star => (
                            <span key={star} className={`${getClass(star, rating.avg)}`}>
                                <span className={`star__border ${getClass(star, rating.avg)}`} />
                            </span>
                        ))}
                        <div className="star__rating">{rating.avg.toFixed(1)}</div>
                    </div>

                    <div className="people">
                        <span className="fa fa-user-friends" />
                        <div className="people__amount">{rating.count}</div>
                        <p>értékelés</p>
                    </div>
                </div>
            </div>
            <div className="progkat__cover">
                <img src={image} alt="" />
            </div>
        </div>
    );
});
export default ProgKatCard;
