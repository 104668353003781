import React, { memo } from 'react';

import { PREV, NEXT, REGISTRATION, carouselAnimation } from '../../utils/constants';
import Announcement from './Announcement/Announcement';
import RegistrationCard from '../RegistrationCard/RegistrationCard';

const Announcements = memo(function({
    paginate,
    currentContent,
    prevContent,
    animationDirection,
    restartInterval,
    showArrows
}) {
    const paginateHandler = direction => {
        restartInterval();
        paginate(direction);
    };
    debugger;
    return (
        <section className="system-message">
            {showArrows && (
                <div className="arrow u-margin-left-medium" onClick={() => paginateHandler(PREV)}>
                    <span className="fa fa-angle-left" />
                </div>
            )}
            {prevContent ? (
                <div
                    key={prevContent.id}
                    className={`animation-wrapper ${carouselAnimation[animationDirection].slideOutAnimation}`}
                >
                    {prevContent === REGISTRATION ? (
                        <RegistrationCard />
                    ) : (
                        <Announcement text={prevContent.text} title={prevContent.title} />
                    )}
                </div>
            ) : null}
            {currentContent ? (
            <div
                key={currentContent.id}
                className={`animation-wrapper ${carouselAnimation[animationDirection].slideInAnimation}`}
            >
                {currentContent === REGISTRATION ? (
                    <RegistrationCard />
                ) : (
                    <Announcement text={currentContent.text} title={currentContent.title} />
                )}
            </div>
            ) : <RegistrationCard />}
            {showArrows && (
                <div className="arrow u-margin-right-medium" onClick={() => paginateHandler(NEXT)}>
                    <span className="fa fa-angle-right" />
                </div>
            )}
        </section>
    );
});
export default Announcements;
