import axios from '../../axios/announcement-axios';
import { GET_ANNOUNCEMENTS_SUCCESS } from './actionTypes';

export const loadAnnouncements = () => async dispatch => {
    try {
        const response = await axios.get('/');
        document.edlHeaderHelper.getUser(user => {
            dispatch({ type: GET_ANNOUNCEMENTS_SUCCESS, payload: { messages: response.data, user } });
        })
    } catch (error) {}
};
