import React, { memo } from 'react';
import PropTypes from 'prop-types';

const propTypes = {
    elementStyle: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired
};

const defaultProps = {
    title: null,
    separator: false,
    content: ''
};

const TextWidget = memo(function({ elementStyle, content, title, separator }) {
    const widget = title ? (
        <div className="text-widget">
            <div className={elementStyle.toLowerCase()}>
                <h2>{title}</h2>
                <div dangerouslySetInnerHTML={{ __html: content.replaceAll('_blank', '_self') }} />
            </div>
            {separator && <div className="text-separator" />}
        </div>
    ) : (
        <div className="text-widget">
            <div className={elementStyle.toLowerCase()} dangerouslySetInnerHTML={{ __html: content.replaceAll('_blank', '_self') }} />
            {separator && <div className="text-separator" />}
        </div>
    );
    return widget;
});
TextWidget.propTypes = propTypes;
TextWidget.defaultProps = defaultProps;
export default TextWidget;
