import React, { Fragment, memo } from 'react';
import PropTypes from 'prop-types';

import HighlightedArticle from '../HighlightedArticle/HighlightedArticle';
import ArticleCard from '../ArticleCard/ArticleCard';
import Announcements from '../Announcements/Announcements';

const propTypes = {
    changeRubric: PropTypes.func,
    pinned: PropTypes.bool,
    rubric: PropTypes.object
};

const defaultProps = {
    pinned: false,
    rubric: null,
    changeRubric: null
};

const Highlighted = memo(function({
    pinned,
    rubric,
    paginate,
    currentContent,
    prevContent,
    animationDirection,
    restartInterval,
    pinnedArticles,
    articleById,
    filteredArticleProps,
    changeRubric,
    showArrows,
    showAnnouncements
}) {
    const [highlightedArticle, ...articles] = (pinned ? pinnedArticles : rubric.articles).map(id => articleById[id]);
    const renderArticles = articles.map(article => (
        <ArticleCard key={article.id} {...filteredArticleProps[article.id]} />
    ));

    return (
        <Fragment>
            <HighlightedArticle
                top={pinned}
                {...filteredArticleProps[highlightedArticle.id]}
                changeRubric={changeRubric}
            />
            {pinned && showAnnouncements && (
                <Announcements
                    paginate={paginate}
                    currentContent={currentContent}
                    prevContent={prevContent}
                    animationDirection={animationDirection}
                    restartInterval={restartInterval}
                    showArrows={showArrows}
                />
            )}
            <div className={`${pinned ? 'top-articles' : 'section-articles'}`}>
                <div className="container-3">{renderArticles}</div>
            </div>
        </Fragment>
    );
});
Highlighted.propTypes = propTypes;
Highlighted.defaultProps = defaultProps;
export default Highlighted;
