export const GET_STATIC_DATA_SUCCESS = 'GET_STATIC_DATA_SUCCESS';
export const GET_STATIC_DATA_FAILED = 'GET_STATIC_DATA_FAILED';
export const CHANGE_RUBRIC = 'CHANGE_RUBRIC';
export const SELECT_TAG = 'SELECT_TAG';
export const DESELECT_TAG = 'DESELECT_TAG';
export const CLEAR_FILTERS = 'CLEAR_FILTERS';
export const PAGINATE = 'PAGINATE';
export const SELECT_AUTHOR = 'SELECT_AUTHOR';
export const PAGINATE_ANNOUNCEMENT = 'PAGINATE_ANNOUNCEMENT';

export const GET_PROGKAT_CARD_INFO_SUCCESS = 'GET_PROGKAT_CARD_INFO_SUCCESS';
export const CLEAR_PROGKAT_CARD_INFO = 'CLEAR_PROGKAT_CARD_INFO';

export const GET_ANNOUNCEMENTS_SUCCESS = 'GET_ANNOUNCEMENTS_SUCCESS';
export const CHANGE_SLIDE = 'CHANGE_SLIDE';
