import React, {Component, createRef} from 'react';
import {Link, Redirect} from 'react-router-dom';
import {connect} from 'react-redux';

import ProgKatCard from '../../components/ProgkatCard/ProgkatCard';
import AuthorBox from '../../components/AuthorBox/AuthorBox';
import {TEST_URL, CLIPBOARD_TIMEOUT, ARTICLE_TEXT, CAROUSEL, getPosition} from '../../utils/constants';
import {FacebookShareButton} from 'react-share';
import withScrollPositionHandler from '../../hoc/withScrollPositionHandler';
import {getArticleById, getRubricIdByName} from '../../store/filters/data';
import {getProgkatCardInfo} from '../../store/actions';
import {CLEAR_PROGKAT_CARD_INFO} from '../../store/actions/actionTypes';
import TextWidget from '../../components/TextWidget/TextWidget';
import ImageWidget from '../../components/ImageWidget/ImageWidget';
import withLrsRequestHandler from '../../hoc/withLrsRequestHandler';
import Taglist from "../../components/Taglist/Taglist";

@withLrsRequestHandler
@withScrollPositionHandler
class Article extends Component {
    state = {
        clipBoardSuccess: false
    };
    clipBoardInput = createRef();

    componentDidMount() {
        const article = this.props.articleById[this.props.match.params.id];
        this.props.sendToLrs('read', 'article', article.id);
        if (article && article.programId) {
            this.props.getProgkatCard(article.programId);
        }
    }

    componentWillUnmount() {
        this.props.clear();
    }

    tagQueryStringHandler = (tag, rubric) => {
        this.props.history.push({
            pathname: '/rovatok',
            search: `?rovat=${rubric.id}&címkék=${tag}`
        });
    };

    copyToClipboard = () => {
        if (!this.clipBoardSuccess) {
            this.clipBoardInput.current.select();
            document.execCommand('copy');
            this.setState({clipBoardSuccess: true}, () =>
                setTimeout(() => this.setState({clipBoardSuccess: false}), CLIPBOARD_TIMEOUT)
            );
        }
    };

    render() {
        const {articleProgKatCardInfo, articleById, sendToLrs} = this.props;
        const article = articleById[this.props.match.params.id];
        if (!article) return <Redirect to={'/404'}/>;
        let contentElements = null;
        if (article.contentElements) {
            contentElements = article.contentElements
                .sort((con1, con2) => (con1.order < con2.order ? -1 : con1.order === con2.order ? 0 : 1))
                .map((contentElement, index) => {
                    return contentElement.elementType === ARTICLE_TEXT ? (
                        <TextWidget
                            key={contentElement.id}
                            content={contentElement.content.text}
                            title={contentElement.content.title}
                            separator={contentElement.content.separator}
                            elementStyle={contentElement.style}
                        />
                    ) : (
                        <ImageWidget
                            key={contentElement.id}
                            images={contentElement.content.images}
                            caption={contentElement.content.caption}
                            elementStyle={contentElement.style}
                            position={
                                contentElement.style !== CAROUSEL
                                    ? getPosition(article.contentElements, index, contentElement.style)
                                    : null
                            }
                        />
                    );
                });
        }

        return (
            <div key={article.id} className="article">
                <div className="article__cover">
                    <img
                        alt=""
                        src={
                            process.env.NODE_ENV === 'production'
                                ? article.images.cover.url
                                : TEST_URL + article.images.cover.url
                        }
                    />
                </div>
                <div className="article__title">
                    <div className="info-lane">
                        <div className="label">{article.articleType}</div>
                        <Link to={`/szerzo/${article.author.name}/${article.author.id}`}
                              arai-label={"szerző: " + article.author.name}>{article.author.name}</Link>
                    </div>
                    <h1 className="title">{article.title}</h1>
                </div>
                <div className="article__lead">{article.lead}</div>
                {contentElements}
                {article.content && <TextWidget elementStyle="normal" content={article.content}/>}
                <div className="article__tags">
                    <Taglist tags={article.tags} rubricId={this.props.rubricIdByName[article.rubric]}/>
                </div>
                {typeof article.programId === 'string' && articleProgKatCardInfo !== null && (
                    <ProgKatCard {...articleProgKatCardInfo} sendToLrs={sendToLrs}/>
                )}
                <AuthorBox author={article.author}/>
                <div className="article__social-media">
                    <FacebookShareButton quote={article.title} url={window.location.href}>
                        <span title="Megosztás" className="fa fa-facebook-f"/>
                    </FacebookShareButton>
                    <button title="Cikk url vágolapra" className="SocialMediaShareButton fa fa-copy" onClick={this.copyToClipboard}/>
                    {this.state.clipBoardSuccess && <div className="clip-board-success">URL másolva a vágólapra</div>}
                    <input tabIndex={-1} ref={this.clipBoardInput} value={window.location.href} aria-label="copy content" readOnly/>
                </div>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    articleProgKatCardInfo: state.progkat.articleProgKatCardInfo,
    articleById: getArticleById(state.data.rubrics),
    rubricIdByName: getRubricIdByName(state.data.rubrics)
});

const mapDispatchToProps = dispatch => ({
    getProgkatCard: id => dispatch(getProgkatCardInfo(id)),
    clear: () => dispatch({type: CLEAR_PROGKAT_CARD_INFO})
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Article);
